<template>
    <div>
        <st-data-table
            :items="items"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="isLoading"
            @view="onView"
            responsive="sm"
            hover
            :serverSideSorting="true"
            stateModule="internalDocuments/list"
            @goToLink="goToLink"
            @viewStatus="viewSigningStatus"
            :headClass="tableHeadClass"
            :firstColumnSelectable="toSign"
            :allowSelectAll="toSign"
            @itemChecked="checkItems"
        >
        </st-data-table>

        <internal-document-signing-status-modal
            ref="signing-status-modal"
        >

        </internal-document-signing-status-modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { InternalDocumentsModel } from '@/modules/internal-documents/models/internal-documents-model';
    import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
    import InternalDocumentSigningStatusModal from '@/modules/internal-documents/components/InternalDocumentSigningStatusModal.vue';

    const { fields } = InternalDocumentsModel;

    export default {
        name: 'InternalDocumentsListTable',
        components: {
            InternalDocumentSigningStatusModal
        },
        props: {
            toSign: {
                type: Boolean,
                default: false
            },
            headClass: {
                type: String,
                default: 'st-data-table-head'
            }
        },
        data() {
            return {
                presenter: InternalDocumentsModel.presenter,
            };
        },
        computed: {
            ...mapGetters({
                rows: 'internalDocuments/list/rows',
                loading: 'shared/loading',
                currentUser: 'auth/currentUser',
            }),
            fields() {
                let selectedColumn = {label: '', key: 'selected'};
                let tableFields = [
                    fields.assigned_staff_user_name.extendField({ customField: true, portalTarget: 'enable-truncate'}),
                    fields.parent_application_identification_number.extendField({ customField: true, portalTarget: 'enable-truncate', linkable: true }),
                    fields.identification_number.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                    fields.allocated_document_series.extendField({ sortable: true}),
                    fields.created_date.extendField({ sortable: true}),
                    fields.application_date.extendField({ sortable: true}),
                    fields.internal_status.extendField({ labelType: true , sortable: true}),
                    fields.application_type_name.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                    fields.response_due_in_days.extendField({ bold: true, sortable: true}),
                ];

                if (this.toSign) {
                    tableFields.unshift(selectedColumn);
                }

                return tableFields;
            },
            isLoading () {
                return this.loading['internalDocuments/get'] ;
            },
            hasPermissionToViewStaffApplications() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            actions() {
                let actions = [];

                if (this.hasPermissionToViewStaffApplications) {
                    actions.push({
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    });
                }

                actions.push({
                    name: 'viewStatus',
                    icon: 'signature',
                    tooltipText: this.$t('GENERAL.ACTIONS.VIEW_STATUS'),
                    customIcon: false,
                    type: 'primary',
                    hideOnRow: true,
                });

                return actions;
            },
            items() {
                return this.rows.map((row) => {
                    let hideAction = true
                    let hideActionName = ''
                
                    if (['sign_pending', 'signed'].includes(row.internal_status)) {
                        hideActionName = 'viewStatus';     
                    }

                    return {
                        ...row,
                        hideAction,
                        hideActionName
                    }
                });
            },
            tableHeadClass() {
                let headerClass = this.headClass;

                if (this.toSign) {
                    headerClass += ' first-column-check';
                }

                return headerClass;
            },
        },
        methods: {
            ...mapActions({
                selectRecord: 'internalDocuments/form/find',
                doSelect: 'internalDocuments/list/doSelect',
            }),
            async onView(data) {
                if (!data?.item?.id) return;

                if (this.hasPermissionToViewStaffApplications) {
                    this.$router.push({
                        name: 'internalDocumentEdit',
                        params: {
                            id: data.item.id,
                        },
                    });
                }
            },
            goToLink(data) {
                this.$router.push({ name: 'applicationView', params: { id: data.item.parent_application_id }});
            },
            async viewSigningStatus(data) {
                if (!data?.item?.id) return;
                await this.selectRecord(data.item.id);
                this.$refs['signing-status-modal'].show();
            },
            checkItems(data) {
                this.doSelect(data);
            },
        },
    };
</script>
